import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import './index.sass'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const WorkTutorial = ({country}) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}}}) {
        nodes {
          frontmatter {
            country
            tutorial_section {
              title
              subtitle
              list {
                title
                description
                item_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                is_cta
              }
            }
          }
        }
      }
    }
  `)

  const tutorialSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.tutorial_section

  return (
    <div className="work-tutorial">
      <div className="tutorial-header">
        <AnchorLink to="#app-stores" className="title" stripHash>{t(tutorialSection.title)}</AnchorLink>
        <span className="subtitle">{t(tutorialSection.subtitle)}</span>
      </div>
      <div className="content">
        {tutorialSection.list.map(listItem => {
         const innerContent = (
          <React.Fragment>
           <GatsbyImage
            image={getImage(listItem.item_image)}
            className="item-image"
            alt=""
           />
           <span className="item-title">{t(listItem.title)}</span>
           <span className="item-description">{t(listItem.description)}</span>
          </React.Fragment>
         )
         const wrapper = listItem.is_cta
          ? (children) => <AnchorLink to="#app-stores" className="item item--clickable" stripHash>{children}</AnchorLink>
          : (children) => <div key={listItem.title} className="item">{children}</div>

         return wrapper(innerContent)
        })}
        <div className="desktop-background" />
      </div>
    </div>
  )
}
