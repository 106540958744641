import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { Link } from '../common/link'
import './index.sass'

export const ReferralTerms = ({ country }) => {
  const { allMdx } = useStaticQuery(graphql`
      query {
        allMdx(filter: {frontmatter: {slug: {eq: "referral-terms"}}}) {
          nodes {
            frontmatter {
              country
              content {
                title
                items
                button_title
                button_link
              }
            }
          }
        }
      }
  `)
  const { t } = useTranslation()
  const { content } = allMdx.nodes.find(({ frontmatter }) => frontmatter.country === country).frontmatter

  return (
    <div className="referral-terms">
      <div className="content">
        <h1 className="title">{t(content.title)}</h1>
        <ol>
          {content.items.map(item => (
            <li key={item}>{t(item)}</li>
          ))}
        </ol>
        <Link
          to={content.button_link}
          className="item"
        >
          <button className="button" type="button">
            {t(content.button_title)}
          </button>
        </Link>
      </div>
    </div>
  )
}