import React from 'react'
import { Header as PageHeader } from '../../common/header'
import './index.sass'
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { SignupForm } from "../signup-form/signup-form";
import { switchMarket } from '../../../services/switch-market';
import { windowGlobal } from '../../../utils/window-global';
import { AppContext } from '../../../layouts';
import {getResolvedLanguage} from "../../../services/get-language";

export const SPSignup = ({ country, slug }) => {
  const { t } = useTranslation()
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            country
            slug
            main_title
            secondary_title
            form_title
            form_subtitle
            first_name_required_message
            last_name_required_message
            email_required_message
            phone_required_message
            first_name_title
            last_name_title
            email_title
            phone_title
            submit_form_button_text
            form_success_title
            form_success_subtitle
            form_success_string_1
            form_success_string_2
            click_link_to_restore_password
            email_already_registered
            reset_your_password
            check_email
            actions {
              title
            }
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    const path = windowGlobal.location.pathname.slice(1)
    const slashIndex = path.indexOf('/')
    const pathMarket = path.slice(0, slashIndex)
    if (pathMarket !== country) {
      switchMarket(pathMarket, getResolvedLanguage())
    }
  }, [])

  const data = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter

  return (
    <div className="signup__container">
      <PageHeader
        withWhiteLogo
        linkColor="#FFFFFF"
        buttonColor="#FFFFFF"
        activeLinkColor="#FFFFFF"
        country={country} />
      <div className="signup__titles">
        <span className="signup__titles__main-title">{t(data.main_title)}</span>
      </div>
      <div className="signup__info">
        <div className="signup__info__container">
          <span className="signup__info__title">{t(data.secondary_title)}</span>
          <div className="signup__info__steps">
            {data.actions.map((action, index) => (
              <div className={`signup__info__step${index === data.actions.length - 1 ? ' signup__info__step--last' : ''}`}>
                <div className="signup__info__stepNumberBox">
                  <span className="signup__info__stepNumber">{index + 1}</span>
                </div>
                <span className="signup__info__step__title">{t(action.title)}</span>
                {index !== data.actions.length - 1 && <div className="signup__info__step__divider"></div>}
              </div>
            ))}
          </div>
        </div>
      </div>
      <SignupForm
        formTitle={data.form_title}
        formSubtitle={data.form_subtitle}
        firstNameRequiredMessage={data.first_name_required_message}
        lastNameRequiredMessage={data.last_name_required_message}
        emailRequiredMessage={data.email_required_message}
        phoneRequiredMessage={data.phone_required_message}
        firstNameTitle={data.first_name_title}
        lastNameTitle={data.last_name_title}
        emailTitle={data.email_title}
        phoneTitle={data.phone_title}
        submitFormButtonText={data.submit_form_button_text}
        formSuccessTitle={data.form_success_title}
        formSuccessSubtitle={data.form_success_subtitle}
        formSuccessString1={data.form_success_string_1}
        formSuccessString2={data.form_success_string_2}
        emailAlreadyRegisteredText={data.email_already_registered}
        resetPasswordText={data.reset_your_password}
        checkEmail={data.check_email}
      />
    </div>
  )
}