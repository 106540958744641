import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { AngleDown } from './assets/angle-down'
import { DropdownMenu } from '../dropdown-menu/dropdown-menu'
import './market-selector.sass'
import { supportedCountryCodesArray } from "../../../config/countries";
import { countriesLanguages } from "../../../config/countriesLanguages";
import { switchMarket } from "../../../services/switch-market";
import { getCountryIcon } from "../../../services/get-country-icon";
import { languagesData } from "../../../config/languages";
import i18n from "../../../services/i18n";

const selectorCountries = supportedCountryCodesArray

export const MarketLanguageSelector = ({ textStyle, currentCountryCode }) => {
    const currentLanguage = i18n.language

  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {type: {eq: "page"}}}) {
        nodes {
          frontmatter {
            country
            slug
          }
        }
      }
    }
  `)

  return (
    <div className="MarketLanguageSelectors">
      <DropdownMenu
        outerComponent={(
          <div className="Selector">
            <img src={getCountryIcon(currentCountryCode)} className="Selector__countryImg" />
            <span style={textStyle} className="Selector__countryCode">{currentCountryCode}</span>
            <AngleDown fill={textStyle.color} />
          </div>
        )}
        items={selectorCountries.map(market => (
          <div
            onClick={() => {
                const language = countriesLanguages[market].includes(currentLanguage) ? currentLanguage : countriesLanguages[market][0]
                switchMarket(market, language)
            }}
            className="Selector__item">
            <img src={getCountryIcon(market)} />
            <span className={`Selector__item__title ${market === currentCountryCode ? '--active' : ''}`}>
              {t(`common.${market}`)}
            </span>
          </div>
        ))}
      />
      <div className="MarketLanguageSelectors__row">
        <span className="MarketLanguageSelectors__row__label">Language</span>
        <DropdownMenu
          outerComponent={(
            <div className="Selector">
              <span style={textStyle} className="Selector__countryCode">{languagesData.shortTitle[currentLanguage]}</span>
              <AngleDown fill={textStyle.color} />
            </div>
          )}
          items={countriesLanguages[currentCountryCode].map(lang => {
            const isActive = lang === currentLanguage
            return (
              <div
                onClick={() => {
                  switchMarket(currentCountryCode, lang)
                }}
                className="Selector__item">
                <div className="Selector__item__checkboxContainer">
                  <input
                    type="checkbox"
                    checked={isActive}
                    className={`Selector__item__checkboxContainer__checkbox ${isActive ? '--active' : ''}`}
                  />
                  <label for="checkbox" className="Selector__item__checkboxContainer__label"></label>
                </div>
                <span className={`Selector__item__title ${isActive ? '--active' : ''}`}>
                  {languagesData.longTitle[lang]}
                </span>
              </div>
            )
          })}
        />
      </div>
    </div>
  )
}
