import React from 'react'
import './index.sass'
import ogramLogo from './assets/ogram-logo.svg'
import {useMutation} from "react-query";
import {forgotPassword} from "../../services/api/api";
import {toast} from "react-toastify";
import {extractQueryParameter} from "../../services/extract-query-parameter/extract-query-parameter";
import {useTranslation} from "react-i18next";

export const ForgotPassword = () => {
  const { t } = useTranslation()

  const [email, setEmail] = React.useState('')
  const [passwordWasSent, setPasswordWasSent] = React.useState(false)
  const userType = extractQueryParameter('userType')

  const forgotPasswordMutation = useMutation((data) => forgotPassword(data), {
      onSuccess: data => {
          if (data.status === 'fail') {
              toast.error(`${data.message}`)
              return
          }

          setPasswordWasSent(true)
          toast.success(t('common.forgot_password.link_was_sent_to_email'))
      }
  })

  return (
    <div className="forgot-password">
      <div className="forgot-password__container">
          <div className="forgot-password__container__top">
              <img src={ogramLogo} />
          </div>
          {!passwordWasSent ? (
              <>
                  <div className="forgot-password__container__texts">
                      <span className="forgot-password__container__texts__main-title">{t('common.forgot_password.forgot_password_question')}</span>
                      <span className="forgot-password__container__texts__sub-title">{t('common.forgot_password.enter_email')}</span>
                  </div>
                  <div className="forgot-password__container__actions">
                      <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder={t('common.forgot_password.email')}
                          className="forgot-password__container__actions__input" />
                      <button
                          onClick={() => forgotPasswordMutation.mutate({email, userType})}
                          className="forgot-password__container__actions__submit"
                      >{t('common.forgot_password.submit')}</button>
                  </div>
              </>
          ) : (
              <div className="fir">
                  <div className="forgot-password__container__success">
                      <span className="forgot-password__container__texts__main-title">{t('common.forgot_password.link_was_sent_short')}</span>
                      <span className="forgot-password__container__texts__sub-title">{t('common.forgot_password.link_was_sent_detailed')}</span>
                  </div>
              </div>
          )}
      </div>
    </div>
  )
}