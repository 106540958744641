import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import enTranslation from '../../translations/en.json'
import elTranslation from '../../translations/el.json'
import arTranslation from '../../translations/ar.json'
import { getResolvedLanguage } from "../get-language";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: getResolvedLanguage(),
    debug: false,
    interpolation: { escapeValue: false },
    react: { useSuspense: false },
    resources: {
      en: {
        translation: enTranslation,
      },
      el: {
       translation: elTranslation,
      },
      ar: {
       translation: arTranslation
      }
    }
  })

export default i18n
