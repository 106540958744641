import React from 'react'
import { Header as PageHeader } from '../../common/header'
import './index.sass'
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import {useTranslation} from "react-i18next";

export const HelpContent = ({ country, slug }) => {
  const { t } = useTranslation()
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            country
            slug
            main_title
            secondary_title
            help_actions {
              image {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
              title
              content
            }
          }
        }
      }
    }
  `)

  const data = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter

  return (
    <div className="help__container">
      <PageHeader
        withWhiteLogo
        linkColor="#FFFFFF"
        buttonColor="#FFFFFF"
        activeLinkColor="#FFFFFF"
        country={country} />
      <div className="help__titles">
        <span className="help__titles__main-title">{t(data.main_title)}</span>
        <span className="help__titles__secondary-title">{t(data.secondary_title)}</span>
      </div>
      <div className="help__actions">
        {data.help_actions.map(action => (
          <div className="help__action">
            <GatsbyImage
              className="help__action__image"
              image={getImage(action.image)}
              alt="Icon" />
            <span className="help__action__title">{t(action.title)}</span>
            <span className="help__action__content">{t(action.content)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}