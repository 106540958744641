import { DesignationHeader } from "../../../../src/components/designation/header";
import { DesignationServicesList } from "../../../../src/components/designation/designation-services";
import { DesignationServices } from "../../../../src/components/designation/services";
import { CommonStatistics } from "../../../../src/components/common/statistics";
import { DesignationPartners } from "../../../../src/components/designation/partners";
import { CommonBenefits } from "../../../../src/components/common/benefits";
import { DesignationTutorial } from "../../../../src/components/designation/tutorial";
import { DesignationStartHire } from "../../../../src/components/designation/start-hire";
import { CommonFAQ } from "../../../../src/components/common/faq";
import { Footer } from "../../../../src/components/common/footer";
import * as React from 'react';
export default {
  DesignationHeader,
  DesignationServicesList,
  DesignationServices,
  CommonStatistics,
  DesignationPartners,
  CommonBenefits,
  DesignationTutorial,
  DesignationStartHire,
  CommonFAQ,
  Footer,
  React
};