import { HireHeader } from "../../../../src/components/hire/header";
import { HireServices } from "../../../../src/components/hire/services";
import { CommonStatistics } from "../../../../src/components/common/statistics";
import { HirePartners } from "../../../../src/components/hire/partners";
import { CommonBenefits } from "../../../../src/components/common/benefits";
import { HireTutorial } from "../../../../src/components/hire/tutorial";
import { HireStartHire } from "../../../../src/components/hire/start-hire";
import { CommonFAQ } from "../../../../src/components/common/faq";
import { Footer } from "../../../../src/components/common/footer";
import * as React from 'react';
export default {
  HireHeader,
  HireServices,
  CommonStatistics,
  HirePartners,
  CommonBenefits,
  HireTutorial,
  HireStartHire,
  CommonFAQ,
  Footer,
  React
};