import React from 'react'

import { getResolvedLanguage } from '../../../services/get-language'
import { GRCookiePolicyEn } from './en'
import { GRCookiePolicyEl } from "./el";

export const GRCookiePolicy = () => {
  const lang = getResolvedLanguage()

  if (lang === 'en') {
    return <GRCookiePolicyEn />
  }

  return <GRCookiePolicyEl />
}