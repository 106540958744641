import { supportedCountryCodesSet } from "../../config/countries";
import { supportedLanguageCodes } from "../../config/languages";
import { windowGlobal } from "../../utils/window-global";

export function switchMarket(country, language) {
  if (!windowGlobal) {
    return
  }

  const path = windowGlobal.location.pathname

  localStorage.setItem('countryCode', country)
  localStorage.setItem('language', language)

  const indexPath = `/${country}/${language}`
  if (path === '/') {
    return indexPath
  }

  const availableMarkets = Array.from(supportedCountryCodesSet).join('|')
  const availableLanguages = Array.from(supportedLanguageCodes).join('|')

  const regExp = new RegExp(`/(${availableMarkets})/(${availableLanguages})(/{0,1}.*)`)
  const matches = windowGlobal.location.pathname.match(regExp)

  if (!!matches) {
    const path = matches[3]
    const searchString = windowGlobal.location.search
    windowGlobal.location.href = `${indexPath}${path}`
    windowGlobal.location.search = searchString
    return
  }

  windowGlobal.location.reload()
}
