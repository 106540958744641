import React from 'react'
import '../index.sass'
import {windowGlobal} from "../../../utils/window-global";

export const GRCookiePolicyEl = () => (
  <div className="cookie-policy">
    <div>
      <div title="header"><p style={{lineHeight: '100%', marginBottom: '0.47in', textDecoration: 'none'}}>
        <br/>
      </p>
      </div>
      <p align="center" style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        <b>ΠΟΛΙΤΙΚΗ </b><span><b>COOKIES</b></span><b>
      </b><span><b>POLICY_</b></span><b>ΙΣΤΟΣΕΛΙΔΑ
      </b><span><b>OGRAM</b></span><b> </b>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Αυτή η πολιτική <span>cookies</span>
        ρυθμίζει τη χρήση του ιστοτόπου μας<span>
          </span><span lang="de-DE">https://www.ogram.co/. </span>Το απόρρητό
        σας είναι σημαντικό για εμάς και ως εκ
        τούτου φροντίζουμε να σας παρέχουμε
        όλες τις απαραίτητες πληροφορίες σχετικά
        με τα <span>cookies</span> που χρησιμοποιούνται
        από τον ιστότοπό μας.</p>
      <ol>
        <li><p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
          <b>Τι είναι τα</b><span><b> cookies</b></span></p></li>
      </ol>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Τα <span>cookies</span> είναι μικρά αρχεία
        κειμένου με πληροφορίες που ενδέχεται
        να αποθηκευτούν στον υπολογιστή σας ή
        στο τερματικό της συσκευής σας (<span>tablet</span>,
        <span>smartphone</span>, <span>laptop</span>)
        ενώ περιηγείστε στον ιστότοπό μας.
        Στόχος τους είναι να δημιουργήσουν μια
        πιο φιλική προς το χρήστη εμπειρία για
        τους επισκέπτες του ιστότοπού μας,
        επιτρέποντάς μας να θυμόμαστε τις
        προτιμήσεις σας για ένα συγκεκριμένο
        χρονικό διάστημα, κάθε φορά που επιλέγετε
        να επισκεφθείτε τον ιστότοπό μας.
      </p>
      <ol start={2}>
        <li><p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
          <b>Ποια είδη </b><span><b>cookies</b></span><b>
          χρησιμοποιούμε και με ποιον τρόπο </b>
        </p></li>
      </ol>
      <p style={{lineHeight: '108%', marginLeft: '0.03in', marginBottom: '0.11in'}}>
        Αυτός ο ιστότοπος χρησιμοποιεί διαφορετικά
        είδη <span>cookies</span>. Όσον αφορά στη
        διάρκεια αποθήκευσής τους, τα <span>cookies</span>
        χωρίζονται σε προσωρινά <span>cookies</span>
        (<span>session</span> <span>cookies</span>),
        τα οποία αποθηκεύονται προσωρινά στη
        συσκευή σας και διαγράφονται όταν
        κλείνετε το πρόγραμμα περιήγησής σας,
        και σε μόνιμα <span>cookies</span>
        (<span>persistent</span> <span>cookies</span>),
        τα οποία αποθηκεύονται στη συσκευή σας
        για μεγαλύτερο χρονικό διάστημα.</p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Όταν περιηγείστε στον ιστότοπό μας,
        ενδέχεται να αποθηκευτούν στη συσκευή
        σας τα ακόλουθα είδη <span>cookies</span>:</p>
      <table width={553} cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={93}/>
          <col width={96}/>
          <col width={213}/>
          <col width={58}/>
          <col width={41}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={102} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
            <i><span style={{background: 'transparent'}}>Απαραίτητα
                    </span></i><span><i><span style={{background: 'transparent'}}>cookies</span></i></span><i><span
            style={{background: 'transparent'}}>
                      (4)</span></i></p>
            <p style={{orphans: 0, widows: 0}}><span
              style={{background: 'transparent'}}>Τα
                    απαραίτητα </span><span><span style={{background: 'transparent'}}>cookies</span></span><span
              style={{background: 'transparent'}}>
                    είναι αναγκαία για να μπορείτε να
                    χρησιμοποιείτε τον ιστότοπό μας καθώς
                    επιτρέπουν βασικές λειτουργίες του
                    ιστότοπού μας, όπως η πλοήγηση στην
                    σελίδα και η πρόσβαση σε ασφαλείς
                    περιοχές του ιστότοπου. Ο ιστότοπος
                    δεν μπορεί να λειτουργήσει σωστά χωρίς
                    αυτά τα </span><span><span
              style={{background: 'transparent'}}>cookies</span></span><span style={{background: 'transparent'}}>,
                    επομένως δεν μπορείτε να εξαιρέσετε
                    τη χρήση τους.</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={93} height={22} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span style={{background: 'transparent'}}>Όνομα</span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Πάροχος</span></p>
          </td>
          <td width={213} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Σκοπός</span></p>
          </td>
          <td width={58} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Διάρκεια ζωής</span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Είδος</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={93} height={57} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>CookieConsent</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Cookiebot</span></span></p>
          </td>
          <td width={213} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Αποθηκεύει την
                    κατάσταση συγκατάθεσης του χρήστη
                    σχετικά με τα </span><span><span
            style={{background: 'transparent'}}>cookies</span></span><span style={{background: 'transparent'}}>
                    για τον τρέχοντα τομέα.</span></p>
          </td>
          <td width={58} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={93} height={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>test_cookie</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={213} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Χρησιμοποιείται
                    για να ελέγξει εάν το πρόγραμμα
                    περιήγησης του χρήστη υποστηρίζει
                  </span><span><span style={{background: 'transparent'}}>cookies</span></span><span
            style={{background: 'transparent'}}>.</span></p>
          </td>
          <td width={58} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>ημέρα</span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={93} height={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>i</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={213} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Διατηρεί τις
                    καταστάσεις των χρηστών σε όλα τα
                    αιτήματα της σελίδας.</span></p>
          </td>
          <td width={58} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span><span style={{background: 'transparent'}}>399
                    </span></span><span style={{background: 'transparent'}}>ημέρες</span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={93} height={38} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>countryCode</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>www.ogram.co</span></span></p>
          </td>
          <td width={213} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Αυτό το </span><span><span
            style={{background: 'transparent'}}>cookie</span></span><span style={{background: 'transparent'}}>
                    είναι απαραίτητο για τον καθορισμό
                    της αγοράς του τρέχοντος χρήστη.</span></p>
          </td>
          <td width={58} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <p style={{lineHeight: '100%', orphans: 0, widows: 0, marginBottom: '0.11in'}}>
        <br/>
        <br/>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br/>
        <br/>
      </p>
      <table width={553} cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={97}/>
          <col width={98}/>
          <col width={194}/>
          <col width={73}/>
          <col width={40}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={68} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
            <span><i><span style={{background: 'transparent'}}>Cookies</span></i></span><i><span
            style={{background: 'transparent'}}>
                      προτίμησης (2)</span></i></p>
            <p style={{orphans: 0, widows: 0}}><span
              style={{background: 'transparent'}}>Τα
                  </span><span><span style={{background: 'transparent'}}>cookies</span></span><span
              style={{background: 'transparent'}}>
                    προτίμησης επιτρέπουν στον ιστότοπό
                    μας να θυμάται πληροφορίες που αλλάζουν
                    τον τρόπο ανταπόκρισης ή εμφάνισης
                    του ιστότοπου, όπως η γλώσσα που
                    προτιμάτε ή η περιοχή στην οποία
                    βρίσκεστε.</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={97} height={22} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span style={{background: 'transparent'}}>Όνομα</span></p>
          </td>
          <td width={98} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Πάροχος</span></p>
          </td>
          <td width={194} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Σκοπός</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Διάρκεια ζωής</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Είδος</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={97} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>@@scroll#</span></span></p>
          </td>
          <td width={98} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>www.ogram.co</span></span></p>
          </td>
          <td width={194} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={97} height={90} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>language</span></span></p>
          </td>
          <td width={98} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>www.ogram.co</span></span></p>
          </td>
          <td width={194} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καθορίζει την
                    προτιμώμενη γλώσσα του επισκέπτη.
                    Επιτρέπει στον ιστότοπο να ορίζει την
                    προτιμώμενη γλώσσα κατά την εκ νέου
                    είσοδο του επισκέπτη.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <p style={{lineHeight: '100%', orphans: 0, widows: 0, marginBottom: '0.11in'}}>
        <br/>
        <br/>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br/>
        <br/>
      </p>
      <table width={553} cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={104}/>
          <col width={92}/>
          <col width={193}/>
          <col width={73}/>
          <col width={40}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={50} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
            <span><i><span style={{background: 'transparent'}}>Cookies</span></i></span><i><span
            style={{background: 'transparent'}}>
                      στατιστικής ανάλυσης (7)</span></i></p>
            <p style={{orphans: 0, widows: 0}}><span
              style={{background: 'transparent'}}>Τα
                  </span><span><span style={{background: 'transparent'}}>cookies</span></span><span
              style={{background: 'transparent'}}>
                    στατιστικής ανάλυσης μας βοηθούν να
                    κατανοήσουμε πώς αλληλεπιδράτε με
                    τον ιστότοπό μας συλλέγοντας και
                    αναφέροντας πληροφορίες ανώνυμα.</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={22} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span style={{background: 'transparent'}}>Όνομα</span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Πάροχος</span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Σκοπός</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Διάρκεια ζωής</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Είδος</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={126}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ga</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί ένα
                    μοναδικό αναγνωριστικό που χρησιμοποιείται
                    για τη δημιουργία στατιστικών δεδομένων
                    σχετικά με τον τρόπο με τον οποίο ο
                    επισκέπτης χρησιμοποιεί τον ιστότοπο.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span><span style={{background: 'transparent'}}>399
                    </span></span><span style={{background: 'transparent'}}>ημέρες</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={126}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ga_#</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Χρησιμοποιείται
                    από το </span><span><span style={{background: 'transparent'}}>Google</span></span><span
            style={{background: 'transparent'}}>
                  </span><span><span style={{background: 'transparent'}}>Analytics</span></span><span
            style={{background: 'transparent'}}>
                    για τη συλλογή δεδομένων σχετικά με
                    τον αριθμό των επισκέψεων ενός χρήστη
                    στον ιστότοπο καθώς και για τις
                    ημερομηνίες της πρώτης και της πιο
                    πρόσφατης επίσκεψης.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span><span style={{background: 'transparent'}}>399
                    </span></span><span style={{background: 'transparent'}}>ημέρες</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={91} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>__vw_tab_guid</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί δεδομένα
                    σχετικά με τη συμπεριφορά των επισκεπτών
                    του ιστότοπου. Αυτό χρησιμοποιείται
                    για εσωτερική ανάλυση και βελτιστοποίηση
                    ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={91} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>_ym#:#_reqNum</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί
                    στατιστικά στοιχεία για τη συμπεριφορά
                    των χρηστών στον ιστότοπο. Χρησιμοποιείται
                    εσωτερικά για στοιχεία ανάλυσης από
                    τον διαχειριστή του ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={91} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>_ym_retryReqs</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί
                    στατιστικά στοιχεία για τη συμπεριφορά
                    των χρηστών στον ιστότοπο. Χρησιμοποιείται
                    για εσωτερικά αναλυτικά στοιχεία από
                    τον χειριστή του ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={91} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>yandexuid</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί δεδομένα
                    σχετικά με τη συμπεριφορά των επισκεπτών
                    του ιστότοπου. Αυτό χρησιμοποιείται
                    για εσωτερική ανάλυση και βελτιστοποίηση
                    ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={90} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>ymex</span></span></p>
          </td>
          <td width={92} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={193} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί δεδομένα
                    σχετικά με τη συμπεριφορά των επισκεπτών
                    του ιστότοπου. Αυτό χρησιμοποιείται
                    για εσωτερική ανάλυση και βελτιστοποίηση
                    ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <p style={{lineHeight: '100%', orphans: 0, widows: 0, marginBottom: '0.11in'}}>
        <br/>
        <br/>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br/>
        <br/>
      </p>
      <table width={553} cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={135}/>
          <col width={80}/>
          <col width={173}/>
          <col width={73}/>
          <col width={39}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={85} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
            <span><i><span style={{background: 'transparent'}}>Cookies</span></i></span><i><span
            style={{background: 'transparent'}}>
                      Εμπορικής προώθησης (21)</span></i></p>
            <p style={{orphans: 0, widows: 0}}><span
              style={{background: 'transparent'}}>Τα
                  </span><span><span style={{background: 'transparent'}}>cookies</span></span><span
              style={{background: 'transparent'}}>
                    εμπορικής προώθησης χρησιμοποιούνται
                    για την παρακολούθηση των επισκεπτών
                    σε ιστότοπους. Ο σκοπός είναι να
                    προβάλλονται διαφημίσεις που είναι
                    σχετικές και ελκυστικές για τον
                    μεμονωμένο χρήστη και, ως εκ τούτου,
                    πιο πολύτιμες για αυτούς που τις
                    δημοσιεύουν και για τους διαφημιστές.</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={22} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span style={{background: 'transparent'}}>Όνομα</span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Πάροχος</span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Σκοπός</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Διάρκεια ζωής</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Είδος</span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={109}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_fbp</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span><span style={{background: 'transparent'}}>Meta
                      Platforms, Inc.</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Χρησιμοποιείται
                    από το </span><span lang="nl-NL"><span style={{background: 'transparent'}}>Facebook
                    </span></span><span style={{background: 'transparent'}}>για την
                    παράδοση μιας σειράς διαφημιστικών
                    προϊόντων, όπως η υποβολή προσφορών
                    σε πραγματικό χρόνο από τρίτους
                    διαφημιστές.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>3 </span></span><span
            style={{background: 'transparent'}}>μήνες</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={213}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>IDE</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Χρησιμοποιείται
                    από το </span><span><span style={{background: 'transparent'}}>Google</span></span><span
            style={{background: 'transparent'}}>
                  </span><span><span style={{background: 'transparent'}}>DoubleClick</span></span><span
            style={{background: 'transparent'}}>
                    για την καταχώριση και την αναφορά
                    των ενεργειών του χρήστη του ιστότοπου
                    μετά την προβολή ή την επιλογή μίας
                    από τις διαφημίσεις του διαφημιζόμενου
                    με σκοπό τη μέτρηση της αποτελεσματικότητας
                    μιας διαφήμισης και την παρουσίαση
                    στοχευμένων διαφημίσεων στον χρήστη.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={161}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
                  <span><span style={{background: 'transparent'}}>pagead/landing
                      [x2]</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Συλλέγει δεδομένα
                    για τη συμπεριφορά των επισκεπτών από
                    πολλούς ιστότοπους, προκειμένου να
                    εμφανίσει πιο σχετικές διαφημίσεις.
                    Αυτό επιτρέπει επίσης στον ιστότοπο
                    να περιορίσει τον αριθμό των εμφανίσεων
                    ίδιων διαφημίσεων.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0in'
            }}>
            <span><span style={{background: 'transparent'}}>pagead/viewthrough</span></span></p>
            <p align="left" style={{orphans: 0, widows: 0}}><span><span
              style={{background: 'transparent'}}>conversion/undefined</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Παρακολουθεί το
                    ρυθμό εναλλαγής μεταξύ του χρήστη και
                    των διαφημιστικών </span><span><span style={{background: 'transparent'}}>banner</span></span><span
            style={{background: 'transparent'}}>
                    στον ιστότοπο. Αυτό χρησιμεύει για τη
                    βελτιστοποίηση της συνάφειας των
                    διαφημίσεων στον ιστότοπο.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={22} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>pagead/1p-conversion/undefined</span></span>
            </p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={230}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>pagead/1p-user-list/#</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Παρακολουθεί εάν
                    ο χρήστης έχει δείξει ενδιαφέρον για
                    συγκεκριμένα προϊόντα ή συμβάντα σε
                    πολλούς ιστότοπους και εντοπίζει πώς
                    ο χρήστης πλοηγείται μεταξύ των
                    ιστοτόπων. Αυτό χρησιμοποιείται για
                    τη μέτρηση των διαφημιστικών προσπαθειών
                    και διευκολύνει την πληρωμή τελών
                    παραπομπής μεταξύ ιστοσελίδων.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={109}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_gcl_au</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Χρησιμοποιείται
                    από το </span><span><span style={{background: 'transparent'}}>Google</span></span><span
            style={{background: 'transparent'}}>
                  </span><span><span style={{background: 'transparent'}}>AdSense</span></span><span
            style={{background: 'transparent'}}>
                    για πειραματισμό σε σχέση με την
                    αποτελεσματικότητα διαφημίσεων σε
                    ιστότοπους που χρησιμοποιούν τις
                    υπηρεσίες τους.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>3 </span></span><span
            style={{background: 'transparent'}}>μήνες</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>_ym#_lastHit</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>_ym#_lsid</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>_ym_d</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Περιέχει την
                    ημερομηνία της πρώτης επίσκεψης του
                    επισκέπτη στον ιστότοπο.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={265}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_isad</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Αυτό το </span><span><span
            style={{background: 'transparent'}}>cookie</span></span><span style={{background: 'transparent'}}>
                    χρησιμοποιείται για να προσδιορίσει
                    εάν ο επισκέπτης έχει κάποιο λογισμικό
                    αποκλεισμού διαφημίσεων στο πρόγραμμα
                    περιήγησής του. Αυτές οι πληροφορίες
                    μπορούν να χρησιμοποιηθούν για να
                    καταστήσουν το περιεχόμενο του
                    ιστότοπου μη προσβάσιμο στους
                    επισκέπτες, εάν ο ιστότοπος χρηματοδοτείται
                    από διαφημίσεις τρίτων.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>μέρα</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_synced</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Παρακολουθεί την
                    αλληλεπίδραση του χρήστη με τη
                    λειτουργία γραμμής αναζήτησης του
                    ιστότοπου. Αυτά τα δεδομένα μπορούν
                    να χρησιμοποιηθούν για να παρουσιάσουν
                    στον χρήστη σχετικά προϊόντα ή
                    υπηρεσίες.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_uid</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Συλλέγει δεδομένα
                    σχετικά με την πλοήγηση και τη
                    συμπεριφορά του χρήστη στον ιστότοπο.
                    Χρησιμοποιείται για τη σύνταξη
                    στατιστικών αναφορών και κατευθυντήριων
                    για τον ιδιοκτήτη του ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_visorc</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Αποθηκεύει
                    πληροφορίες για ενέργειες που έχουν
                    πραγματοποιηθεί από τον χρήστη κατά
                    την τρέχουσα επίσκεψή του στον ιστότοπο,
                    συμπεριλαμβανομένων των αναζητήσεων
                    με λέξεις-κλειδιά.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>μέρα</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>_ym_wv2rf:#:0</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Μόνιμο</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={126}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>metrika_enabled</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Χρησιμοποιείται
                    για την παρακολούθηση επισκεπτών σε
                    πολλούς ιστότοπους, προκειμένου να
                    εμφανίζει σχετικές διαφημίσεις
                    αναλόγως των προτιμήσεων του επισκέπτη.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό </span>
          </p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>watch</span></span><span
              style={{background: 'transparent'}}>/#</span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>webvisor</span></span><span
              style={{background: 'transparent'}}>/#</span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={91} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>yabs</span></span><span
              style={{background: 'transparent'}}>-</span><span><span
              style={{background: 'transparent'}}>sid</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Καταχωρεί δεδομένα
                    σχετικά με τη συμπεριφορά των επισκεπτών
                    του ιστότοπου. Χρησιμοποιείται για
                    εσωτερική ανάλυση και βελτιστοποίηση
                    ιστότοπου.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span style={{background: 'transparent'}}>Προσωρινό</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={160}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>yuidss</span></span></p>
          </td>
          <td width={80} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={173} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                  <span style={{background: 'transparent'}}>Συλλέγει πληροφορίες
                    σχετικά με τη συμπεριφορά των χρηστών
                    σε πολλούς ιστότοπους. Αυτές οι
                    πληροφορίες χρησιμοποιούνται για τη
                    βελτιστοποίηση της συνάφειας των
                    διαφημίσεων στον ιστότοπο.</span></p>
          </td>
          <td width={73} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 </span></span><span
            style={{background: 'transparent'}}>έτος</span></p>
          </td>
          <td width={39} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <ol>
        <li value={3}><p align="left" style={{lineHeight: '108%', marginBottom: '0.11in'}}>
          <b>Πως να διαχειριστείτε την χρήση των
          </b><span><b>cookies</b></span></p></li>
      </ol>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Μπορούμε να αποθηκεύσουμε <span>cookies</span>
        στη συσκευή σας με την προϋπόθεση ότι
        έχετε συναινέσει στη χρήση τους. Η
        συγκατάθεσή σας δεν απαιτείται για τα
        <span>cookies</span> που θεωρούνται τεχνικά
        απαραίτητα για τη σύνδεση σας με τον
        ιστότοπό μας ή για την παροχή της
        υπηρεσίας διαδικτύου μας (απαραίτητα
        <span>cookies</span>). Ως εκ τούτου,
        χρειαζόμαστε την άδειά σας για να
        χρησιμοποιήσουμε όλες τις άλλες
        κατηγορίες <span>cookies</span> και για
        το σκοπό αυτό θα σας ζητήσουμε να
        επιτρέψετε τη χρήση τους επιλέγοντας
        το αντίστοιχο κουμπί στο <span>banner</span>
        που θα εμφανιστεί μόλις εισέλθετε στον
        ιστότοπό μας.</p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Μπορείτε πάντα να τροποποιήσετε τις
        προτιμήσεις σας για τα <span>cookies</span>
        απλά επισκεπτόμενοι την ενότητα
        «Διαχείριση <span>cookies</span>» που
        είναι διαθέσιμη στον ιστότοπό μας.</p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Επιπλέον, δεδομένου ότι η χρήση των
        <span>cookies</span> υποστηρίζεται από
        διάφορα προγράμματα περιήγησης όπως
        το <span>Google</span> <span>Chrome</span>,
        το <span>Mozilla</span> <span>Firefox</span>,
        το <span>Safari</span> και το <span>Opera</span>,
        μπορείτε επίσης να επιλέξετε να
        απενεργοποιήσετε τη χρήση των <span>cookies</span>
        ή να διαγράψετε αυτά που έχουν ήδη
        αποθηκευτεί στη συσκευή σας, μεταβαίνοντας
        στη σχετική ενότητα στις ρυθμίσεις του
        προγράμματος περιήγησής σας. Σε μια
        τέτοια περίπτωση όμως, είναι πολύ πιθανό
        να μην έχετε πρόσβαση σε ορισμένες
        λειτουργίες του ιστότοπού μας.</p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Για περισσότερες πληροφορίες σχετικά
        με το ποιοι είμαστε, πώς μπορείτε να
        επικοινωνήσετε μαζί μας και πώς
        επεξεργαζόμαστε τα προσωπικά σας
        δεδομένα, επισκεφθείτε την Πολιτική
        Απορρήτου μας.</p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        Αυτή η πολιτική ενδέχεται να επικαιροποιείται
        ανά διαστήματα. Για τη διευκόλυνσή σας,
        μπορείτε να βρείτε στο τέλος της παρούσας
        την ημερομηνία τελευταίας ενημέρωσής
        της.</p>
      <br/>
      <span><b>Επικαιροποιήθηκε την 01.03.2023</b></span>
      <br/>
      <br/>
      <br/>
      <span onClick={() => {
        if (!windowGlobal) {
          return
        }
        windowGlobal.Cookiebot.show()
      }} style={{color: '#1032CF', display: 'flex', cursor: 'pointer', fontWeidht: '600'}}>Διαχείριση cookies</span>
    </div>
  </div>
)