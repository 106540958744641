import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import './index.sass'

export const TermsAndConditions = ({ country, children }) => {
  const { allMdx } = useStaticQuery(graphql`
      query {
        allMdx(filter: {frontmatter: {slug: {eq: "terms-conditions-markdown"}}}) {
          nodes {
            frontmatter {
              title
              country
            }
            body
          }
        }
      }
  `)

  const mdx = allMdx.nodes.find(node => node.frontmatter.country === country)

  return (
    <div className="terms-conditions">
      <p className="content">
        <MDXRenderer>
          {mdx.body}
        </MDXRenderer>
      </p>
    </div>
  )
}