import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useHorizontalPaginator = (pageLength, scrolledRef) => {
  const [currentIndex, setIndex] = useState(0)

  const scrollHandler = useDebouncedCallback(({ nativeEvent }) => {
    const elementWidth = nativeEvent.target.clientWidth
    const scrolled = nativeEvent.target.scrollLeft
    const scrollWidth = nativeEvent.target.scrollWidth
    const scrollableArea = scrollWidth - elementWidth
    const scrolledPercent = scrolled * 100 / scrollableArea
    if (scrolledPercent >= 100) {
      return setIndex(pageLength - 1)
    }
    const currentPage = Math.floor(pageLength * scrolledPercent / 100)
    setIndex(currentPage)
  }, 300, { maxWait: 300 })

  const scrollToIndex = (index) => {
    setIndex(index)

    const elementWidth = scrolledRef.current.clientWidth
    const scrollWidth = scrolledRef.current.scrollWidth
    const scrollableArea = scrollWidth - elementWidth

    if (index === 0) {
      scrolledRef.current.scrollLeft = 0
      return
    }

    scrolledRef.current.scrollLeft = scrollableArea * ((index + 1) / pageLength)
  }

  return { currentIndex, scrollHandler, scrollToIndex }
}
