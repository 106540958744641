import React from 'react'
import { useTranslation } from 'react-i18next'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'

export const Statistics = ({country}) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "index"}}}) {
        nodes {
          frontmatter {
            slug
            country
            home_statistics_section {
              title
              description
              list {
                title
                amount
                stat_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const statsSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.home_statistics_section

  return (
    <div className="home-statistics">
      <span className="title">{t(statsSection.title)}</span>
      <span className="description">{t(statsSection.description)}</span>
      <div className="stats-container">
        {statsSection.list.map(stat => (
          <div className="item" key={stat.title}>
            <GatsbyImage
              image={getImage(stat.stat_image)}
              alt=""
              className="image"
              backgroundColor="transparent"
            />
            <span className="percent-label">{t(stat.amount)}</span>
            <span className="item-description">{t(stat.title)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
