export const countryCodes = {
  UAE: 'uae',
  GREECE: 'gr',
  SA: 'sa'
}

export const supportedCountryCodesArray = [countryCodes.UAE, countryCodes.GREECE, countryCodes.SA]
export const supportedCountryCodesSet = new Set(supportedCountryCodesArray)

export const defaultCountryCode = countryCodes.UAE
