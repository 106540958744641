import React, { useState } from 'react'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";
import * as Yup from 'yup'

import warningIcon from "../content/assets/warning.svg";
import { submitSp } from "../../../services/api/api";
import { extractQueryParameter } from "../../../services/extract-query-parameter/extract-query-parameter";
import { Link } from '../../common/link'

const emailDomainsMap = {
    "gmail.com": "https://mail.google.com/",
    "yahoo.com": "https://mail.yahoo.com/",
    "outlook.com": "https://outlook.live.com/",
    "aol.com": "https://mail.aol.com/",
    "icloud.com": "https://www.icloud.com/",
    "hotmail.com": "https://outlook.live.com/",
};

const getEmailProviderUrl = (email) => {
    const domain = email.split('@')[1]
    if (!domain) {
        return null
    }
    const provider = emailDomainsMap[domain] ?? null

    return provider !== null ? provider : `https://${domain}`
}

export const SignupForm = (
    {
        formTitle,
        formSubtitle,
        firstNameRequiredMessage,
        lastNameRequiredMessage,
        emailRequiredMessage,
        phoneRequiredMessage,
        firstNameTitle,
        lastNameTitle,
        emailTitle,
        phoneTitle,
        submitFormButtonText,
        formSuccessTitle,
        formSuccessSubtitle,
        formSuccessString1,
        formSuccessString2,
        emailAlreadyRegisteredText,
        resetPasswordText,
        checkEmail
    }) => {
    const { t } = useTranslation()

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isNotUniqueEmail, setIsNotUniqueEmail] = useState(false)
    const [email, setEmail] = useState('')

    const submitSpMutation = useMutation((data) => submitSp(data), {
        onSuccess: data => {
            if (data.status === 'fail') {
                const errors = data.errors ?? {}
                if (errors.hasOwnProperty("email")) {
                    setIsNotUniqueEmail(true)
                }

                toast.error(`${Object.values(errors).join(', ')}`)
                return
            }

            setFormSubmitted(true)
        }
    })

    return (
        <div className="signup__form" style={isNotUniqueEmail ? { bottom: '-410px' } : {}}>
            <div className="signup__form__container">
                {!formSubmitted ? (
                    <>
                        <span className="signup__form__title">{t(formTitle)}</span>
                        <span className="signup__form__subtitle">{t(formSubtitle)}</span>
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                phone: ''
                            }}
                            validationSchema={Yup.object().shape({
                                firstName: Yup.string().required(t(firstNameRequiredMessage)),
                                lastName: Yup.string().required(t(lastNameRequiredMessage)),
                                email: Yup.string().required(t(emailRequiredMessage)),
                                phone: Yup.string().required(t(phoneRequiredMessage)),
                            })}
                            onSubmit={(submitData) => {
                                submitSpMutation.mutate({
                                    first_name: submitData.firstName,
                                    last_name: submitData.lastName,
                                    email: submitData.email,
                                    phone: submitData.phone,
                                    referral_id: extractQueryParameter('r'),
                                    referral_type: extractQueryParameter('t')
                                })

                                setEmail(submitData.email)
                            }}>
                            {formikProps => (
                                <Form>
                                    <div className="signup__form__row">
                                        <div className="signup__form__row__inputs">
                                            <input
                                                className={`signup__form__input${formikProps.errors.firstName && formikProps.touched.firstName ? ' signup__form__input--error' : ''}`}
                                                placeholder={t(firstNameTitle)}
                                                type="text"
                                                name="firstName"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.firstName}
                                            />
                                            <input
                                                className={`signup__form__input${formikProps.errors.lastName && formikProps.touched.lastName ? ' signup__form__input--error' : ''}`}
                                                placeholder={t(lastNameTitle)}
                                                type="text"
                                                name="lastName"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.lastName}
                                            />
                                        </div>
                                        {((formikProps.errors.firstName && formikProps.touched.firstName) || (formikProps.errors.lastName && formikProps.touched.lastName)) && (
                                            <div className="signup__form__row__errors">
                                                {[formikProps.errors.firstName, formikProps.errors.lastName].filter(error => !!error).map(error => (
                                                    <span>{error}</span>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="signup__form__row">
                                        <div className="signup__form__row__inputs">
                                            <input
                                                className={`signup__form__input fullwidth ${(formikProps.errors.email && formikProps.touched.email) || isNotUniqueEmail ? ' signup__form__input--error' : ''}`}
                                                placeholder={t(emailTitle)}
                                                type="email"
                                                name="email"
                                                onChange={(e) => {
                                                    formikProps.handleChange(e)
                                                    setIsNotUniqueEmail(false)
                                                }}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.email}
                                            />
                                        </div>
                                        {formikProps.errors.email && formikProps.touched.email && (
                                            <div className="signup__form__row__errors">
                                                <span>{formikProps.errors.email}</span>
                                            </div>
                                        )}
                                        {isNotUniqueEmail && formikProps.touched.email && (
                                            <div className="signup__form__row__errors--email">
                                                <p>{t(emailAlreadyRegisteredText)}</p>
                                                <p>
                                                    <Link to="/forgot-password?userType=sp">{t(resetPasswordText)}</Link>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="signup__form__row">
                                        <div className="signup__form__row__inputs">
                                            <input
                                                className={`signup__form__input fullwidth ${formikProps.errors.phone && formikProps.touched.phone ? ' signup__form__input--error' : ''}`}
                                                placeholder={t(phoneTitle)}
                                                type="text"
                                                name="phone"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.phone}
                                            />
                                        </div>
                                        {formikProps.errors.phone && formikProps.touched.phone && (
                                            <div className="signup__form__row__errors">
                                                <span>{formikProps.errors.phone}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="signup__form__row">
                                        <button
                                            type="submit"
                                            className="signup__form__button"
                                        >{t(submitFormButtonText)}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                ) : (
                    <div className="signup__success">
                        <img className="signup__success__icon" src={warningIcon} />
                        <span className="signup__success__title">{t(formSuccessTitle)}</span>
                        <span className="signup__success__subtitle">{t(formSuccessSubtitle)}</span>
                        <span className="signup__success__string">{t(formSuccessString1)}</span>
                        <span className="signup__success__string">{t(formSuccessString2)}</span>
                        <Link className="signup__form__button" to={getEmailProviderUrl(email)} target="_blank">{t(checkEmail)}</Link>
                    </div>
                )}
            </div>
        </div>
    )
}