import {windowGlobal} from "../../utils/window-global";

export const extractQueryParameter = (parameterName) => {
    if (!windowGlobal) {
        return null
    }

    const url = new URL(windowGlobal.location.href);
    const params = new URLSearchParams(url.search);

    return params.get(parameterName)
}