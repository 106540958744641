import React from 'react'

import './index.sass'

export const Paginator = ({ count, activeIndex, navigateToIndex, listElementRef }) => {
  const [isVisible, setIsVisible] = React.useState(true)
  const pageArray = React.useRef(new Array(count).fill(0)).current

  React.useEffect(() => {
    const elementWidth = listElementRef.current.clientWidth
    const scrollWidth = listElementRef.current.scrollWidth

    if (elementWidth === scrollWidth) {
      setIsVisible(false)
    }
  }, [listElementRef])

  return !isVisible ? null : (
    <div className="paginator">
      {pageArray.map((_, pageIndex) => (
        <div
          key={pageIndex}
          onClick={() => navigateToIndex(pageIndex)}
          className={`item ${pageIndex === activeIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  )
}
