import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'

export const HireTutorial = ({country}) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "hire"}}}) {
        nodes {
          frontmatter {
            country
            slug
            hire_tutorial_section {
              title
              subtitle
              steps {
                step_label
                step_title
                step_description
                step_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              finish_title
              finish_description
              finish_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const tutorialSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.hire_tutorial_section

  return (
    <div className="tutorial">
      <span className="title">{t(tutorialSection.title)}</span>
      <span className="subtitle">{t(tutorialSection.subtitle)}</span>
      <div className="list">
        {tutorialSection.steps.map((stepItem, index) => (
          <div key={stepItem.step_title} className="item">
            <div
              className={`wrapper ${index % 2 !== 0 ? 'even' : ''}`}
            >
              <GatsbyImage
                image={getImage(stepItem.step_image)}
                alt=""
                className="item-image"
              />
              <div className="item-content">
                <div className="titles">
                  <div className="step-label-container">
                    <span className="label">{t(stepItem.step_label)}</span>
                  </div>
                  <span className="step-title">{t(stepItem.step_title)}</span>
                </div>
                <span className="step-description">{t(stepItem.step_description)}</span>
              </div>
            </div>
            {index === tutorialSection.steps.length - 1 ? (
              <StaticImage
                src="./assets/desktop-connect-last.png"
                className="item-connection-desktop-image last"
                alt=""
              />
            ) : (
              <StaticImage
                src="./assets/desktop-connect-left-to-right.png"
                className={`item-connection-desktop-image ${index % 2 !== 0 ? 'even' : ''}`}
                alt=""
              />
            )}
            <StaticImage
              src="./assets/mobile-connect.png"
              className="item-connection-mobile-image"
              alt=""
            />
          </div>
        ))}
      </div>
      <div className="finish">
        <GatsbyImage
          image={getImage(tutorialSection.finish_image)}
          className="icon"
          alt=""
        />
        <span className="title">{t(tutorialSection.finish_title)}</span>
        <span className="description">{t(tutorialSection.finish_description)}</span>
      </div>
    </div>
  )
}
