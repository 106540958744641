import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { useHorizontalPaginator } from '../../../hooks/use-horizontal-paginator'
import { Paginator } from '../../../components/common/paginator'
import './index.sass'

export const DesignationPartners = ({ slug, country }) => {
  const scrolledRef = React.useRef()
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            country
            slug
            hire_partners_section {
              title
              list {
                description
                partner_image {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
                author_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                author_name
                partner_name
                partner_underline
              }
            }
          }
        }
      }
    }
  `)

  const partnersSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.hire_partners_section

  const { currentIndex, scrollHandler, scrollToIndex } = useHorizontalPaginator(partnersSection.list.length, scrolledRef)

  return (
    <div className="hire-partners">
      <span className="title">{t(partnersSection.title)}</span>
      <div className="list" onScroll={scrollHandler} ref={scrolledRef}>
        {partnersSection.list.map(partnerItem => (
          <div className="item" key={partnerItem.author_name}>
            <div
              className="partner-image"
              style={{ backgroundImage: `url(${partnerItem.partner_image.childImageSharp.original.src})` }}
            />
            <span className="description">{t(partnerItem.description)}</span>
            <div className="author-container">
              <GatsbyImage
                image={getImage(partnerItem.author_image)}
                className="author-image"
                alt=""
              />
              <div className="text-container">
                <span className="name">{partnerItem.author_name}</span>
                <div className="verification-container">
                  <StaticImage
                    src="./assets/checkmark.png"
                    alt=""
                    className="checkmark-image"
                  />
                  <span className="label">{t(partnerItem.partner_underline)}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <Paginator
          count={partnersSection.list.length}
          navigateToIndex={scrollToIndex}
          activeIndex={currentIndex}
          listElementRef={scrolledRef}
        />
      </div>
    </div>
  )
}
