import { defaultLanguageCode } from "../../config/languages";
import { windowGlobal } from "../../utils/window-global";
import i18n from "../i18n";

export function getResolvedLanguage() {
  if (!windowGlobal) {
    return defaultLanguageCode
  }

  return typeof i18n !== 'undefined' ? i18n.language : defaultLanguageCode
}
