import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'

export const CommonFAQ = ({ slug, country }) => {
  const [activeQuestion, setActiveQuestion] = useState(null)

  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            country
            slug
            common_faq_section {
              title
              questions {
                title
                description
              }
            }
          }
        }
      }
    }
  `)

  const faqSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.common_faq_section

  return (
    <div className="faq">
      <span className="title">{t(faqSection.title)}</span>
      <div className="question-list">
        {faqSection.questions.map((questionItem, index) => (
          <div
            className={`question-item ${index === activeQuestion ? 'active' : ''}`}
            key={questionItem.title}
            onClick={() => {
              if (activeQuestion === index) {
                setActiveQuestion(null)
                return
              }
              setActiveQuestion(index)
            }}
          >
            <div className="question-header">
              <span className="label">{t(questionItem.title)}</span>
              {index === activeQuestion ? (
                <StaticImage
                  src="./assets/minus.png"
                  alt=""
                  className="image"
                />
              ) : (
                <StaticImage
                  src="./assets/plus.png"
                  alt=""
                  className="image"
                />
              )}
            </div>
            <span className="description">{t(questionItem.description)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
