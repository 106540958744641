import React from 'react'
import '../index.sass'
import {windowGlobal} from "../../../utils/window-global";

export const KSACookiePolicyEn = () => (
  <div className="cookie-policy">
    <div>
      <div title="header"><p style={{lineHeight: '100%', marginBottom: '0.47in', textDecoration: 'none'}}>
        <br/>
      </p>
      </div>
      <p align="center" style={{lineHeight: '108%', marginBottom: '0.11in'}}>
        <span><b>COOKIES POLICY_OGRAM WEBSITE</b></span></p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>This cookies policy regulates the use of our
              website https://www.ogram.co/. Your privacy is important to us and
              therefore we make sure to provide you with all the necessary
              information regarding cookies which are used by our website. </span>
      </p>
      <ol>
        <li><p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
          <span><b>What are cookies</b></span></p></li>
      </ol>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>Cookies are small text files with information
              which may be stored on your computer or on your terminal device
              (tablet, smartphone, laptop) while you are browsing on our website.
              They aim to create a more user-friendly experience for our website’s
              visitors by enabling us to remember your preferences for a certain
              period of time each time you choose to visit our website. </span>
      </p>
      <ol start={2}>
        <li><p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
          <span><b>How and which types of cookies we use</b></span></p></li>
      </ol>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>This site uses different types of cookies. With
              reference to their term of storage, cookies are divided into session
              cookies, which are stored temporarily on your device and are deleted
              when you close your browser, and persistent cookies, which are stored
              on your device for a longer period of time. </span>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>When you are browsing through our website the
              following types of cookies may be stored on your device:</span></p>
      <table cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={99}/>
          <col width={96}/>
          <col width={202}/>
          <col width={64}/>
          <col width={41}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={102} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
                    <span><i><span style={{background: 'transparent'}}>Necessary
                          cookies (4)</span></i></span></p>
            <p style={{orphans: 0, widows: 0}}><span><span
              style={{background: 'transparent'}}>Necessary
                        cookies are essential in order to make our website usable by
                        enabling basic functions of our website such as page navigation
                        and access to secure areas of the website. The website cannot
                        function properly without these cookies therefore you may not opt
                        out from their use.</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={99} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>Name</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Provider</span></span></p>
          </td>
          <td width={202} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Purpose</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Lifespan</span></span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Type</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={99} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>CookieConsent</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Cookiebot</span></span></p>
          </td>
          <td width={202} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Stores
                        the user's cookie consent state for the current domain.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={99} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>test_cookie</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={202} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Used to
                        check if the user's browser supports cookies.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 day</span></span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={99} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>i</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={202} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Preserves
                        users’ states across page requests.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>399 days</span></span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={99} height={21}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>countryCode</span></span></p>
          </td>
          <td width={96} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>www.ogram.co</span></span></p>
          </td>
          <td width={202} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>This
                        cookie is needed to define the current user's market.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={41} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <p
        style={{lineHeight: '100%', orphans: 0, widows: 0, marginBottom: '0.11in'}}>
        <br/>
        <br/>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br/>
        <br/>
      </p>
      <table cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={98}/>
          <col width={98}/>
          <col width={200}/>
          <col width={64}/>
          <col width={40}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={50} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
                    <span><i><span style={{background: 'transparent'}}>Preference
                          cookies (2)</span></i></span></p>
            <p style={{orphans: 0, widows: 0}}><span><span
              style={{background: 'transparent'}}>Preference
                        cookies enable our website to remember information that changes
                        the way the website behaves or looks, like your preferred language
                        or the region that you are in.</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={98} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>Name</span></span></p>
          </td>
          <td width={98} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Provider</span></span></p>
          </td>
          <td width={200} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Purpose</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Lifespan</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Type</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={98} height={5} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}>
            <p style={{orphans: 0, widows: 0}}>
              <span><span style={{background: 'transparent'}}>@@scroll#</span></span></p>
          </td>
          <td width={98} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>www.ogram.co</span></span></p>
          </td>
          <td width={200} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={98} height={73}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>language</span></span></p>
          </td>
          <td width={98} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>www.ogram.co</span></span></p>
          </td>
          <td width={200} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Determines
                        the preferred language of the visitor. Allows the website to set
                        the preferred language upon the visitor's re-entry.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <p style={{lineHeight: '100%', orphans: 0, widows: 0, marginBottom: '0.11in'}}>
        <br/>
        <br/>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br/>
        <br/>
      </p>
      <table cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={104}/>
          <col width={94}/>
          <col width={198}/>
          <col width={64}/>
          <col width={40}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={50} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
                    <span><i><span style={{background: 'transparent'}}>Statistic
                          cookies (7)</span></i></span></p>
            <p style={{orphans: 0, widows: 0}}><span><span
              style={{background: 'transparent'}}>Statistic
                        cookies help us to understand how you interact with our website by
                        collecting and reporting information anonymously.</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>Name</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Provider</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Purpose</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Lifespan</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Type</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={39}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ga</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        a unique ID that is used to generate statistical data on how the
                        visitor uses the website.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>399 days</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={74}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ga_#</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Used by
                        Google Analytics to collect data on the number of times a user has
                        visited the website as well as dates for the first and most recent
                        visit.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>399 days</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={57}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>__vw_tab_guid</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        data on visitors' website behavior. This is used for internal
                        analysis and website optimization.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={57}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym#:#_reqNum</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        statistical data on users' behavior on the website. Used for
                        internal analytics by the website operator.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={57}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_retryReqs</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        statistical data on users' behavior on the website. Used for
                        internal analytics by the website operator.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={57}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>yandexuid</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        data on visitors' website behavior. This is used for internal
                        analysis and website optimization.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={104} height={56}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>ymex</span></span></p>
          </td>
          <td width={94} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={198} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        data on visitors' website behavior. This is used for internal
                        analysis and website optimization.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <p style={{lineHeight: '100%', orphans: 0, widows: 0, marginBottom: '0.11in'}}>
        <br/>
        <br/>
      </p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br/>
        <br/>
      </p>
      <table cellPadding={5} cellSpacing={0}>
        <colgroup>
          <col width={135}/>
          <col width={87}/>
          <col width={174}/>
          <col width={64}/>
          <col width={40}/>
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={5} width={541} height={68} valign="top"
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p

            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0.11in'
            }}>
                    <span><i><span style={{background: 'transparent'}}>Marketing
                          cookies (21)</span></i></span></p>
            <p style={{orphans: 0, widows: 0}}><span><span
              style={{background: 'transparent'}}>Marketing
                        cookies are used to track visitors across websites. The intention
                        is to display advertisements that are relevant and engaging for
                        the individual user and thereby more valuable for publishers and
                        third party advertisers.</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>Name</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Provider</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Purpose</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Lifespan</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Type</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={74}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_fbp</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Meta
                        Platforms, Inc.</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Used by
                        Facebook to deliver a series of advertisement products such as
                        real time bidding from third party advertisers.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>3 months</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>IDE</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Used by
                        Google DoubleClick to register and report the website user's
                        actions after viewing or clicking one of the advertiser's ads with
                        the purpose of measuring the efficacy of an ad and to present
                        targeted ads to the user.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
                    <span><span style={{background: 'transparent'}}>pagead/landing
                        [x2]</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Collects
                        data on visitor behavior from multiple websites, in order to
                        present more relevant advertisements. This also allows the website
                        to limit the number of times that the same advertisements are
                        shown.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={109}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0,
              marginBottom: '0in'
            }}>
            <span><span style={{background: 'transparent'}}>pagead/viewthrough</span></span></p>
            <p align="left" style={{orphans: 0, widows: 0}}><span><span
              style={{background: 'transparent'}}>conversion/undefined</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Tracks
                        the conversion rate between the user and the advertisement banners
                        on the website. This serves to optimize the relevance of the
                        advertisements on the website.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={22}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>pagead/1p-conversion/undefined</span></span>
          </p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={178}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>pagead/1p-user-list/#</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Tracks if
                        the user has shown interest in specific products or events across
                        multiple websites and detects how the user navigates between
                        sites. This is used for measurement of advertisement efforts and
                        facilitates payment of referral-fees between websites.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={74}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_gcl_au</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Google</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Used by
                        Google AdSense for experimenting with advertisement efficiency
                        across websites using their services.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>3 months</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym#_lastHit</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym#_lsid</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={39}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_d</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Contains
                        the date of the visitor's first visit to the website.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={143}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_isad</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>This
                        cookie is used to determine if the visitor has any adblocker
                        software in their browser. This information can be used to make
                        website content inaccessible to visitors if the website is
                        financed with third-party advertisement.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 day</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={91}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_synced</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Tracks
                        the user’s interaction with the website’s search-bar-function.
                        This data can be used to present the user with relevant products
                        or services.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={91}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_uid</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Collects
                        data on the user’s navigation and behavior on the website. This
                        is used to compile statistical reports and heatmaps for the
                        website owner.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={91}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_visorc</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Saves
                        information of actions that have been carried out by the user
                        during the current visit to the website, including searches with
                        keywords included.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 day</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>_ym_wv2rf:#:0</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Persistent</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTML</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={74}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>metrika_enabled</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Used to
                        track visitors on multiple websites, in order to present relevant
                        advertisement based on the visitor's preferences.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session </span></span>
          </p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>watch/#</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={5}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>webvisor/#</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <br/>
          </p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Pixel</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={57}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>yabs-sid</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Registers
                        data on visitors' website behavior. This is used for internal
                        analysis and website optimization.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Session</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        <tr valign="top">
          <td width={135} height={90}
              style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{
              orphans: 0,
              widows: 0
            }}>
            <span><span style={{background: 'transparent'}}>yuidss</span></span></p>
          </td>
          <td width={87} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>Yandex</span></span></p>
          </td>
          <td width={174} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
                    <span><span style={{background: 'transparent'}}>Collects
                        information on user behavior on multiple websites. This
                        information is used in order to optimize the relevance of
                        advertisement on the website.</span></span></p>
          </td>
          <td width={64} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>1 year</span></span></p>
          </td>
          <td width={40} style={{background: 'transparent', border: '1px solid #000000', padding: '0.06in'}}><p
            style={{orphans: 0, widows: 0}}>
            <span><span style={{background: 'transparent'}}>HTTP</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
      <ol>
        <li value={3}><p align="left" style={{lineHeight: '108%', marginBottom: '0.11in'}}>
          <span><b>How to manage use of cookies</b></span></p></li>
      </ol>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>We can store cookies on your device provided you
              have consented to their use. Your consent is not required for the
              cookies which are considered technically necessary to connect you to
              our website or to provide you with our internet service (necessary
              cookies). Hence, we need your permission to use all other categories
              of cookies and to this purpose we will ask you to allow their use by
              selecting the respective button in the cookies banner which will
              appear as soon as you enter our website.</span></p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>You can always modify your cookies preferences
              simply by visiting the section ‘Manage Cookies’ which is
              available in our website.</span></p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>Moreover, since the use of cookies is supported by
              various browsers such us Google Chrome, Mozilla Firefox, Safari and
              Opera, you may also choose to deactivate the use of cookies or delete
              those who have already been stored to your device, by visiting the
              relevant section at your browsers settings. In such case though, it
              is quite possible that you may not have access to certain features of
              our website.</span></p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>For more information about who we are, how you can
              contact us and how we process your personal data please visit our
              Privacy Policy.</span></p>
      <p style={{lineHeight: '108%', marginBottom: '0.11in'}}>
            <span>This cookies policy may be updated from time to
              time. For your convenience you may find at the end of this notice the
              date when this policy was last updated.</span></p>
      <br/>
      <span><b>Last Updated on 01.03.2023</b></span>
      <br/>
      <br/>
      <br/>
      <span onClick={() => {
        if (!windowGlobal) {
          return
        }
        windowGlobal.Cookiebot.show()
      }} style={{color: '#1032CF', display: 'flex', cursor: 'pointer', fontWeidht: '600'}}>Manage Cookies</span>
    </div>
  </div>
)