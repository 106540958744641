import { AboutHeader } from "../../../../src/components/about/header";
import { AboutStatistics } from "../../../../src/components/about/statistics";
import { AboutTeam } from "../../../../src/components/about/team";
import { ChooseMode } from "../../../../src/components/common/choose-mode";
import { Footer } from "../../../../src/components/common/footer";
import * as React from 'react';
export default {
  AboutHeader,
  AboutStatistics,
  AboutTeam,
  ChooseMode,
  Footer,
  React
};