import {countryCodes} from "../../config/countries"
import uaeIcon from './assets/uae.png'
import grIcon from './assets/gr.png'
import ksaIcon from './assets/ksa.png'

const countryIcons = {
  [countryCodes.UAE]: uaeIcon,
  [countryCodes.GREECE]: grIcon,
  [countryCodes.SA]: ksaIcon,
}

export function getCountryIcon(countryCode) {
  return countryIcons[countryCode]
}
