import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

import './index.sass'

export const CommonBenefits = ({ slug, country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            slug
            country
            common_benefits_section {
              title_first_line
              title_second_line
              description
              background_image {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
              background_image_second {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
              list {
                title
                list
                item_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const benefitsSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.common_benefits_section
  const mainBgImg = benefitsSection.background_image.childImageSharp.original.src
  const secondaryBgImg = benefitsSection.background_image_second.childImageSharp.original.src

  return (
    <div
      className="benefits"
      style={{
        backgroundImage: `url(${mainBgImg}), url(${secondaryBgImg})`
      }}
    >
      <div className="text-container">
        <span className="title_first_line">
          {t(benefitsSection.title_first_line)}
        </span>
        <span className="title_second_line">
          {t(benefitsSection.title_second_line)}
        </span>
        <span className="description">
          {t(benefitsSection.description)}
        </span>
      </div>
      <div className="benefit-list">
        {benefitsSection.list.map(benefitItem => (
          <div key={benefitItem.title} className="benefit-item">
            <GatsbyImage
              image={getImage(benefitItem.item_image)}
              className="benefit-image"
              alt=""
            />
            <div className="benefit-text-container">
              <span className="title">
                {t(benefitItem.title)}
              </span>
              <div className="list">
                {benefitItem.list.map(item => (
                  <div className="item" key={item}>
                    <StaticImage
                      src="../../../assets/common__checkmark-orange.png"
                      alt=""
                      className="checkmark-image"
                    />
                    <span className="label">
                      {t(item)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
