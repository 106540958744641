import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { HomeHeaderBase } from './base'
import { countryCodes } from '../../../config/countries'
import './index.sass'

export const HomeHeaderUAE = ({ linkColor, buttonColor }) => {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "index"}, country: {eq: "uae"}}}) {
        nodes {
          frontmatter {
            slug
            country
            home_header_section {
              title
              subtitle
              button_title
              statistics {
                title
                subtitle
              }
              statistics_undertext
              header_image {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const headerSection = allMdx.nodes[0].frontmatter.home_header_section

  return (
    <HomeHeaderBase
      linkColor={linkColor}
      buttonColor={buttonColor}
      headerSection={headerSection}
      country={countryCodes.UAE}
    />
  )
}
