import { HomeHeaderGreece } from "../../../../src/components/home/header";
import { Partners } from "../../../../src/components/home/partners";
import { Statistics } from "../../../../src/components/home/statistics";
import { Find } from "../../../../src/components/home/find";
import { ChooseMode } from "../../../../src/components/common/choose-mode";
import { Footer } from "../../../../src/components/common/footer";
import * as React from 'react';
export default {
  HomeHeaderGreece,
  Partners,
  Statistics,
  Find,
  ChooseMode,
  Footer,
  React
};