import * as React from 'react'
import './dropdown-menu.sass'

export const DropdownMenu = ({ outerComponent, items }) => {
  return (
    <div className="DropdownMenu">
      {outerComponent}
      <div className="DropdownMenu__content">
        <ul className="DropdownMenu__content__inner">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <li className="DropdownMenu__item">
                {item}
              </li>
              {index !== items.length - 1 && (
                <div className="DropdownMenu__item__separator" />
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  )
}
