import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { getResolvedLanguage } from '../../services/get-language'
import './index.sass'

export const PrivacyPolicyCompanies = ({ country }) => {
  const { allMdx } = useStaticQuery(graphql`
      query {
        allMdx(filter: {frontmatter: {slug: {eq: "privacy-policy-companies-markdown"}}}) {
          nodes {
            frontmatter {
              title
              country
              language
            }
            body
          }
        }
      }
  `)

  const mdx = allMdx.nodes.find(({ frontmatter  }) => {
    return frontmatter.country === country && frontmatter.language === getResolvedLanguage()
  })

  return (
    <div className="privacy-policy-companies">
      <div className="content">
        <MDXRenderer>
          {mdx.body}
        </MDXRenderer>
      </div>
    </div>
  )
}