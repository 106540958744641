import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import './index.sass'

export const WorkCommunity = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}}}) {
        nodes {
          frontmatter {
            country
            work_community_section {
              subtitle
              title
              list {
                title
                description
                item_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              button_title
              page_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const communitySection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.work_community_section

  return (
    <div className="work-community">
      <GatsbyImage
        image={getImage(communitySection.page_image)}
        className="work-community-image"
        alt=""
      />
      <div className="work-community-content">
        <div className="work-community-content-text-container">
          <span className="subtitle">{t(communitySection.subtitle)}</span>
          <span className="title">{t(communitySection.title)}</span>
        </div>
        <div className="work-community-list">
          {communitySection.list.map(listItem => (
            <div key={listItem.title} className="work-community-item">
              <GatsbyImage
                image={getImage(listItem.item_image)}
                alt=""
                className="item-image"
              />
              <div className="item-text-container">
                <span className="title">{t(listItem.title)}</span>
                <span className="description">{t(listItem.description)}</span>
              </div>
            </div>
          ))}
        </div>
        <AnchorLink
          to="#app-stores"
          title={t(communitySection.button_title)}
          className="work-community-button"
          stripHash
        >
          <span className="label">{t(communitySection.button_title)}</span>
          <StaticImage
            src="../../../assets/common__arrow-right-black.png"
            alt=""
            className="arrow-img"
          />
        </AnchorLink>
      </div>
    </div>
  )
}
