import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Header } from "../../../../src/components/common/header";
import { TermsAndConditions } from "../../../../src/components/common/terms-conditions";
import { Footer } from "../../../../src/components/common/footer";
import * as React from 'react';
export default {
  MDXRenderer,
  Header,
  TermsAndConditions,
  Footer,
  React
};